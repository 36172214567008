module.exports = {
	jsHeadInjects: [],
	showCookieMsg: false,
	title: "OZOM 2.0",
	supportedCountries: ["AR", "BR", "CL", "CO", "MX", "PE", "UY"], // don't sort alphabetically
	defaultCountry: "CL",
	supportedCurrencies: ["ARS", "BRL", "CLP", "COP", "MXN", "PEN", "UYU"], // don't sort alphabetically
	defaultCurrency: "CLP",
	defaultBbox: [[48.879786, -123.171065], [25.456548, -81.033051]],
	showExplore: true,
	serverOptions: [
		{
			label: "DEV2", // TODO: "DEV"?
			id: "dev",
			channel: "ozom",
			docsVersion: "develop",
			accessCookieConfig: {
				name: "glient25idozom",
				path: "/glient25/ozom",
				domain: ".dev.ozom.me",
			},
			refreshCookieConfig: {
				name: "refreshozom",
				path: "/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom",
				domain: ".dev.ozom.me",
			},
			glientWsUrl: "wss://api-client.dev.ozom.me/glient25/ozom",
			redirectUrl: "https://dev.ozom.me/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom/redirect",
			revokeUrl: "https://dev.ozom.me/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom/logout",
			invalidateTokenUrl: "https://dev.ozom.me/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom",
			editAccountUrl: "https://dev.ozom.me/api/profile/c0b6b885586709feafbaf25801bc93a2/redirect",
			migrationWizardUrl: "https://v1upgrade.dev.ozom.me/api/upgrade/?subId={SUBID}#/plugin/cGx1Z2luL296b211cGdyYWRlLmpz",
			geofenceEventUrl: "https://api-phone.dev.ozom.me/api/device/ozom/phone/",
			widgetSetModeUrl: "https://api-client.dev.ozom.me/api/public/ozom/glient/",
		},
		{
			label: "ALPHA",
			id: "alpha",
			channel: "ozom",
			docsVersion: "develop",
			accessCookieConfig: {
				name: "glient25idozom",
				path: "/glient25/ozom",
				domain: ".alpha.roc-connect.com",
			},
			refreshCookieConfig: {
				name: "refreshozom",
				path: "/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom",
				domain: ".alpha.roc-connect.com",
			},
			glientWsUrl: "wss://alpha.roc-connect.com/glient25/ozom",
			redirectUrl: "https://alpha.roc-connect.com/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom/redirect",
			revokeUrl: "https://alpha.roc-connect.com/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom/logout",
			invalidateTokenUrl: "https://alpha.roc-connect.com/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom",
			editAccountUrl: "https://alpha.roc-connect.com/api/profile/c0b6b885586709feafbaf25801bc93a2/redirect",
			migrationWizardUrl: "https://alpha.roc-connect.com/api/upgrade/?subId={SUBID}#/plugin/cGx1Z2luL296b211cGdyYWRlLmpz",
			geofenceEventUrl: "https://alpha.roc-connect.com/api/device/ozom/phone/",
			widgetSetModeUrl: "https://alpha.roc-connect.com/api/public/ozom/glient/",
		},
		{
			label: "SODIMAC",
			id: "prod",
			channel: "ozom",
			docsVersion: "public",
			accessCookieConfig: {
				name: "glient25idozom",
				path: "/glient25/ozom",
				domain: ".prod.ozom.me",
			},
			refreshCookieConfig: {
				name: "refreshozom",
				path: "/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom",
				domain: ".prod.ozom.me",
			},
			glientWsUrl: "wss://api-client.prod.ozom.me/glient25/ozom",
			redirectUrl: "https://prod.ozom.me/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom/redirect",
			revokeUrl: "https://prod.ozom.me/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom/logout",
			invalidateTokenUrl: "https://prod.ozom.me/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/ozom",
			editAccountUrl: "https://prod.ozom.me/api/profile/c0b6b885586709feafbaf25801bc93a2/redirect",
			migrationWizardUrl: "https://v1upgrade.prod.ozom.me/api/upgrade/?subId={SUBID}#/plugin/cGx1Z2luL296b211cGdyYWRlLmpz",
			geofenceEventUrl: "https://api-phone.prod.ozom.me/api/device/ozom/phone/",
			widgetSetModeUrl: "https://api-client.prod.ozom.me/api/public/ozom/glient/",
		},
	],
	devAppId: {
		android: "com.roc_connect.rocapp.smarthome.ozom.dev",
		ios: "com.roc-connect.app.ozom.dev",
	},
	storeId: {
		android: "com.roc_connect.rocapp.smarthome.ozom",
		ios: 1210923399,
	},
};
