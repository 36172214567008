import { AR, BR, CL, CO, MX, PE, UY } from "country-flag-icons/react/3x2"; // eslint-disable-line node/file-extension-in-import
// types
import type { ReactNode } from "react";
import type { Country } from "../../types/misc";

/* eslint-disable @stylistic/jsx-pascal-case */
const FLAGS = {
	AR: <AR />,
	BR: <BR />,
	CL: <CL />,
	CO: <CO />,
	MX: <MX />,
	PE: <PE />,
	UY: <UY />,
} as const satisfies Partial<Record<Country, ReactNode>>;
/* eslint-enable @stylistic/jsx-pascal-case */

export default FLAGS;
